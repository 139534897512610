const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://incar.dvgeo.app' : 'http://192.168.100.106:3054',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://incar.dvgeo.app' : 'http://192.168.100.106:3054',
    api: 'api/',
    apiSocket: 'incar::1.2',
    nameDir: 'incar',
    package: 'app.dvgeo.incar.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyBiLYHDwiDARy0dydb_aett15ZaUbkhfqg',
    appName: 'InCar',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#3B3B3B',
    colorDark: '#FCC30B',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.incar.passenger',
    playStoreDriverId: 'app.dvgeo.incar.driver',
    appStorePassengerId: '6526498482',
    appStoreDriverId: '6526498543',
    email: "pacopinov21@gmail.com",
};
export default config;
